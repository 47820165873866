import React from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenu.scss";

export interface MGILeftMenupProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // items
  linkList: { svg: any; label: string; url: string }[];
  // actions
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenuSeparation = ({}: // linkList,
// ...props
MGILeftMenupProps) => {
  return <div className={"separationbar"}></div>;
};
