import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import React from "react";
import { AuthContext } from "./index";
import { KeycloakAdapter } from "./KeycloakAdapter";

/**
 * Initializes keycloak using react-keycloak and passes it to our generic AuthContext
 */
export default function KeycloakAuthProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const keycloak = initKeycloakFromEnvironment();
  return (
    <ReactKeycloakProvider
      // Needed for tokens to be refreshed after token expiration
      autoRefreshToken={true}
      authClient={keycloak}
      // Avoid a full browser redirect on SSO check
      // See keycloak-js doc: https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
      initOptions={{
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        // Disable session status iframe
        // The session status iframe is a keycloak mechanism that is broken by tracking protection in modern browsers, especially in safari where it causes an infinite full page reload every few seconds
        // See keycloak doc here: https://www.keycloak.org/docs/latest/securing_apps/#session-status-iframe
        checkLoginIframe: false,
      }}
    >
      <ForwardKeycloakToAuthContext>{children}</ForwardKeycloakToAuthContext>
    </ReactKeycloakProvider>
  );
}

/**
 * Retrieves keycloak object from the react-keycloak context and passes it down
 * to our generic AuthContext
 */
function ForwardKeycloakToAuthContext({
  children,
}: {
  children: React.ReactNode;
}) {
  const { keycloak, initialized } = useKeycloak();
  return (
    <AuthContext.Provider
      value={{ initialized, auth: new KeycloakAdapter(keycloak) }}
    >
      {children}
    </AuthContext.Provider>
  );
}

/**
 * Load keycloak configuration values from the following environment variables:
 * - REACT_APP_KEYCLOAK_REALM
 * - REACT_APP_KEYCLOAK_URL
 * - REACT_APP_KEYCLOAK_CLIENT_ID
 * @throws an error if one environment variable is missing
 */
function initKeycloakFromEnvironment(): Keycloak.KeycloakInstance {
  const realm = loadRequiredEnvironmentVariable("REALM");
  const url = loadRequiredEnvironmentVariable("URL");
  const clientId = loadRequiredEnvironmentVariable("CLIENT_ID");
  return Keycloak({ realm, url, clientId });
}

function loadRequiredEnvironmentVariable(envVarName: string): string {
  const fullEnvVarName = `REACT_APP_KEYCLOAK_${envVarName}`;
  const value = process.env[fullEnvVarName];
  if (value === undefined) {
    throw new Error("Missing environment variable " + fullEnvVarName);
  }
  return value;
}
