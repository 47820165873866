import * as buttons from "../tokens/borders";

export const themeDarkColor = {
  borderBtn: buttons.darkBorderBtn,
  txtBtn: "#fff",
};

export const themeLightColor = {
  borderBtn: buttons.lightBorderBtn,
  txtBtn: "#000",
};

const theme = (mode: string) => ({
  color: mode === "dark" ? themeDarkColor : themeLightColor,
  buttons: { ...buttons },
  mode: mode,
});

export default theme;
