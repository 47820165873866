import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./styles/navbar.module.scss";
import { useTranslation } from "react-i18next";
import { useAuth, useUser } from "../AuthContext";
import ListGroups from "./ListGroups";
import { ThemeType } from "./TopBar";
import useOutsideClick from "../UseOutsideClick";
import { UserProfile } from "..";

export default function AccountButton({
  backendUrl,
  //handleUpdateUserTheme,
  theme,
  connectUrl,
}: {
  backendUrl?: string;
  theme?: ThemeType;
  handleUpdateUserTheme?: (theme: ThemeType) => Promise<void>;
  pageName?: string;
  connectUrl?: string;
}) {
  const [showLogout, setLogoutVisible] = useState(false);
  const toggleLogoutVisibility = useCallback(
    () => setLogoutVisible((visible) => !visible),
    []
  );

  const { auth } = useAuth();
  const handleLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const { t } = useTranslation();

  const refDivInside = useRef<HTMLDivElement>(null);

  useOutsideClick(refDivInside, () => {
    showLogout && toggleLogoutVisibility();
  });
  const user = useUser();

  useEffect(() => {
    async function setProfile(user: UserProfile) {
      const backendUrl = process.env["REACT_APP_BACKEND_URL"];
      const url = new URL(`${backendUrl}/user/configure-user`).toString();

      const init: RequestInit = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({
          userCompany: user.company ?? "My Company",
          userId: user.id,
        }),
      };
      await fetch(url, init);
      window.location.reload();
    }
    if (user && !user.defaultTeam) {
      setProfile(user);
    }
  }, [user]);

  const username =
    typeof user === "object"
      ? `${user?.firstName} ${user?.lastName}`
      : undefined;

  const initials =
    typeof user === "object"
      ? `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
      : undefined;

  const teams = typeof user === "object" ? user.teams : undefined;

  const defaultTeam = typeof user === "object" ? user.defaultTeam : undefined;

  return (
    <div ref={refDivInside}>
      <button
        className={classNames(styles.navbar_btnlogin, styles.btn)}
        onClick={toggleLogoutVisibility}
      >
        <div
          className={classNames(
            process.env.REACT_APP_IS_STAGING
              ? styles.compteStaging
              : styles.compte
          )}
        >
          <p className={classNames(styles.letter)}>{initials}</p>
        </div>
      </button>
      {showLogout && (
        <div className={classNames(styles.option_log, "row")}>
          <div className={classNames(styles.navbar_divlogin)}>
            <div
              className={classNames(
                process.env.REACT_APP_IS_STAGING
                  ? styles.compteStaging
                  : styles.compte
              )}
            >
              <p className={classNames(styles.letter)}>{initials}</p>
            </div>
          </div>
          <div className={classNames(styles.userinfo)}>
            <>
              <p className={classNames(styles.nameuser, styles.name)}>
                {username}
              </p>

              {teams && defaultTeam ? (
                <ListGroups
                  backendUrl={backendUrl}
                  defaultValue={defaultTeam}
                  teams={teams}
                />
              ) : (
                ""
              )}
            </>
          </div>

          {connectUrl && (
            <div
              className={classNames(styles.themecolor, styles.navbar_divlogin)}
            >
              <label htmlFor="darkTheme">
                <a href={`${connectUrl}profil`} target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    fill={theme === "dark" ? "white" : "black"}
                  >
                    <g>
                      <path d="M0,0h24v24H0V0z" fill="none" />
                      <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
                    </g>
                  </svg>
                </a>
              </label>
            </div>
          )}

          {/* {pageName === "Connect" && (
            <div className={classNames(styles.themecolor)}>
              <label htmlFor="darkTheme">
                {theme === "dark" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#ffffff"
                  >
                    <rect fill="none" height="24" width="24" />
                    <path d="M12,7c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S14.76,7,12,7L12,7z M2,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0 c-0.55,0-1,0.45-1,1S1.45,13,2,13z M20,13l2,0c0.55,0,1-0.45,1-1s-0.45-1-1-1l-2,0c-0.55,0-1,0.45-1,1S19.45,13,20,13z M11,2v2 c0,0.55,0.45,1,1,1s1-0.45,1-1V2c0-0.55-0.45-1-1-1S11,1.45,11,2z M11,20v2c0,0.55,0.45,1,1,1s1-0.45,1-1v-2c0-0.55-0.45-1-1-1 C11.45,19,11,19.45,11,20z M5.99,4.58c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41l1.06,1.06 c0.39,0.39,1.03,0.39,1.41,0s0.39-1.03,0-1.41L5.99,4.58z M18.36,16.95c-0.39-0.39-1.03-0.39-1.41,0c-0.39,0.39-0.39,1.03,0,1.41 l1.06,1.06c0.39,0.39,1.03,0.39,1.41,0c0.39-0.39,0.39-1.03,0-1.41L18.36,16.95z M19.42,5.99c0.39-0.39,0.39-1.03,0-1.41 c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L19.42,5.99z M7.05,18.36 c0.39-0.39,0.39-1.03,0-1.41c-0.39-0.39-1.03-0.39-1.41,0l-1.06,1.06c-0.39,0.39-0.39,1.03,0,1.41s1.03,0.39,1.41,0L7.05,18.36z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#000000"
                  >
                    <rect fill="none" height="24" width="24" />
                    <path d="M12,3c-4.97,0-9,4.03-9,9s4.03,9,9,9s9-4.03,9-9c0-0.46-0.04-0.92-0.1-1.36c-0.98,1.37-2.58,2.26-4.4,2.26 c-2.98,0-5.4-2.42-5.4-5.4c0-1.81,0.89-3.42,2.26-4.4C12.92,3.04,12.46,3,12,3L12,3z" />
                  </svg>
                )}
              </label>
              <input
                className={classNames(styles.displaynone)}
                type="checkbox"
                name="darkTheme"
                id="darkTheme"
                checked={theme === "dark"}
                onChange={(e) => {
                  if (handleUpdateUserTheme) {
                    handleUpdateUserTheme(e.target.checked ? "dark" : "light");
                  }
                }}
              />
            </div>
          )} */}

          <p className={classNames(styles.logout)} onClick={handleLogout}>
            {t("Logout")}
          </p>
          <div className={classNames(styles.appzone)}>
            <div className={classNames(styles.selectapp)}>
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_APPROVE_URL}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="52.6"
                  viewBox="0 0 52 52.6"
                >
                  <g
                    id="Logo-Approve"
                    transform="translate(3862 -6492.257)"
                    // style="isolation: isolate"
                  >
                    <path
                      id="Rectangle_1425"
                      data-name="Rectangle 1425"
                      d="M0,0H52a0,0,0,0,1,0,0V12A10,10,0,0,1,42,22H10A10,10,0,0,1,0,12V0A0,0,0,0,1,0,0Z"
                      transform="translate(-3862 6522.257)"
                      fill="#064b93"
                    />
                    <path
                      id="Rectangle_1426"
                      data-name="Rectangle 1426"
                      d="M0,0H52a0,0,0,0,1,0,0V20A10,10,0,0,1,42,30H10A10,10,0,0,1,0,20V0A0,0,0,0,1,0,0Z"
                      transform="translate(-3810 6522.257) rotate(180)"
                      fill="#1794cd"
                    />
                    <path
                      id="Tracé_1452"
                      data-name="Tracé 1452"
                      d="M30.771,12.129a17.25,17.25,0,0,1,8.591,3.99c-.733.669-1.445,1.364-2.133,2.067a14.353,14.353,0,1,0,4.912,8.238,25.172,25.172,0,0,1,2.662-1.6,17.332,17.332,0,1,1-14.034-12.7"
                      transform="translate(-3863.865 6487.129)"
                      fill="#004b93"
                      fill-rule="evenodd"
                    />
                    <path
                      id="Tracé_1453"
                      data-name="Tracé 1453"
                      d="M16.057,25.052a17.274,17.274,0,0,1,6.9-2.958,22.4,22.4,0,0,1,3.205,8.34c5.543-7.163,12.463-16.041,18.99-17.352a29.179,29.179,0,0,0-1.9,9.417c-7.008,2.056-13.689,10.048-18.3,15.381-1.628-4.321-4.6-10.709-8.9-12.829"
                      transform="translate(-3861.745 6487.592)"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                    <text
                      id="AP"
                      transform="translate(-3847 6539.257)"
                      fill="#fff"
                      stroke="#feffff"
                      stroke-width="0.6"
                      font-size="17"
                      font-family="Young"
                      letter-spacing="0.03em"
                    >
                      <tspan x="0" y="0">
                        AP
                      </tspan>
                    </text>
                  </g>
                </svg>
              </a>
            </div>

            <div className={classNames(styles.selectapp)}>
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_DASHBOARD_URL}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="104"
                  height="104.6"
                  viewBox="0 0 104 104.6"
                >
                  <g id="Logo-Dashboard" transform="translate(0.13 -0.276)">
                    <g id="Logo-Approve" transform="translate(-0.13 0.276)">
                      <path
                        id="Rectangle_1425"
                        data-name="Rectangle 1425"
                        d="M0,0H104a0,0,0,0,1,0,0V32A10,10,0,0,1,94,42H10A10,10,0,0,1,0,32V0A0,0,0,0,1,0,0Z"
                        transform="translate(0 62)"
                        fill="#064b93"
                      />
                      <path
                        id="Rectangle_1426"
                        data-name="Rectangle 1426"
                        d="M0,0H104a0,0,0,0,1,0,0V52A10,10,0,0,1,94,62H10A10,10,0,0,1,0,52V0A0,0,0,0,1,0,0Z"
                        transform="translate(104 62) rotate(180)"
                        fill="#fbba00"
                      />
                      <text
                        id="DB"
                        transform="translate(29 94)"
                        fill="#fff"
                        stroke="#feffff"
                        stroke-width="0.6"
                        font-size="33"
                        font-family="Young"
                        letter-spacing="0.03em"
                      >
                        <tspan x="0" y="0">
                          DB
                        </tspan>
                      </text>
                      <g
                        id="Groupe_775"
                        data-name="Groupe 775"
                        transform="translate(5.887 0.931)"
                      >
                        <path
                          id="Tracé_1416"
                          data-name="Tracé 1416"
                          d="M20.53,12.814H13.6a.858.858,0,0,0-.788.913V57.66a.858.858,0,0,0,.788.913H20.53a.858.858,0,0,0,.788-.913V13.727A.858.858,0,0,0,20.53,12.814Z"
                          transform="translate(3.013 3.353)"
                          fill="#004b93"
                        />
                        <path
                          id="Tracé_1417"
                          data-name="Tracé 1417"
                          d="M14.929,12.581H8a.858.858,0,0,0-.788.913V57.427A.858.858,0,0,0,8,58.34h6.926a.858.858,0,0,0,.788-.913V13.494A.858.858,0,0,0,14.929,12.581Z"
                          transform="translate(-7.214 2.927)"
                          fill="#fff"
                        />
                        <path
                          id="Tracé_1418"
                          data-name="Tracé 1418"
                          d="M26.361,10.9H19.434a.858.858,0,0,0-.788.913V60.493a.858.858,0,0,0,.788.913h6.926a.858.858,0,0,0,.788-.913V11.815A.858.858,0,0,0,26.361,10.9Z"
                          transform="translate(13.659 -0.138)"
                          fill="#fff"
                        />
                        <path
                          id="Tracé_1419"
                          data-name="Tracé 1419"
                          d="M32.192,9.263H25.263a.858.858,0,0,0-.788.913V64.144a.858.858,0,0,0,.788.913h6.929a.858.858,0,0,0,.788-.913V10.176a.858.858,0,0,0-.788-.913Z"
                          transform="translate(24.302 -3.131)"
                          fill="#004b93"
                        />
                        <path
                          id="Tracé_1420"
                          data-name="Tracé 1420"
                          d="M43.987,9.263H37.058a.858.858,0,0,0-.788.913V64.144a.858.858,0,0,0,.788.913h6.929a.858.858,0,0,0,.788-.913V10.176a.858.858,0,0,0-.788-.913Z"
                          transform="translate(45.838 -3.131)"
                          fill="#004b93"
                        />
                        <path
                          id="Tracé_1421"
                          data-name="Tracé 1421"
                          d="M38.021,7.1H31.094a.858.858,0,0,0-.788.913V67.448a.858.858,0,0,0,.788.913h6.926a.858.858,0,0,0,.788-.913V8.006a.858.858,0,0,0-.788-.913Z"
                          transform="translate(34.949 -7.093)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
            </div>

            <div className={classNames(styles.selectapp)}>
              <a
                target="_blank"
                rel="noreferrer"
                href={process.env.REACT_APP_JETFORCE_URL}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="104"
                  height="104.6"
                  viewBox="0 0 104 104.6"
                >
                  <g id="Logo-JetForce" transform="translate(-0.194 -0.275)">
                    <g id="Logo-Approve" transform="translate(0.194 0.275)">
                      <path
                        id="Rectangle_1425"
                        data-name="Rectangle 1425"
                        d="M0,0H104a0,0,0,0,1,0,0V33A10,10,0,0,1,94,43H10A10,10,0,0,1,0,33V0A0,0,0,0,1,0,0Z"
                        transform="translate(0 61)"
                        fill="#064b93"
                      />
                      <path
                        id="Rectangle_1426"
                        data-name="Rectangle 1426"
                        d="M0,0H104a0,0,0,0,1,0,0V51A10,10,0,0,1,94,61H10A10,10,0,0,1,0,51V0A0,0,0,0,1,0,0Z"
                        transform="translate(104 61) rotate(180)"
                        fill="#98c226"
                      />
                      <text
                        id="JF"
                        transform="translate(33 94)"
                        fill="#fff"
                        stroke="#feffff"
                        stroke-width="0.6"
                        font-size="34"
                        font-family="Young"
                        letter-spacing="0.03em"
                      >
                        <tspan x="0" y="0">
                          JF
                        </tspan>
                      </text>
                    </g>
                    <g
                      id="Groupe_776"
                      data-name="Groupe 776"
                      transform="translate(11.958 22.71)"
                    >
                      <path
                        id="Tracé_1441"
                        data-name="Tracé 1441"
                        d="M16.18,31.349a9.013,9.013,0,1,0-9-9.029,9.015,9.015,0,0,0,9,9.029Z"
                        transform="translate(-7.182 -13.223)"
                        fill="#fff"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Tracé_1442"
                        data-name="Tracé 1442"
                        d="M27.7,22.58a12.075,12.075,0,0,1-12.067,12.1,12.525,12.525,0,0,1-3.224-.429c-2.546,1.888-6.163,8.341-4.422,13.91,13.816.594,23.662-.594,34.175-.4,9.709.168-16.3-15.628,3.716-34.051a39.064,39.064,0,0,0-18.7,5.31,12.5,12.5,0,0,1,.523,3.561h0Z"
                        transform="translate(-6.639 -12.632)"
                        fill="#fff"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Tracé_1443"
                        data-name="Tracé 1443"
                        d="M49.765,22.279a9,9,0,1,1-9-9,8.988,8.988,0,0,1,9,9Z"
                        transform="translate(30.643 -13.283)"
                        fill="#004b93"
                        fill-rule="evenodd"
                      />
                      <path
                        id="Tracé_1444"
                        data-name="Tracé 1444"
                        d="M54.2,33.611A12.069,12.069,0,0,1,42.136,21.544a12.54,12.54,0,0,1,.429-3.224c-4.236-2.272-6.493-5.236-13.006-4.422-15.986,10.826.627,28.081-2.843,34.282h34.1s.2-9.595-3.021-15.09a12.551,12.551,0,0,1-3.592.52Z"
                        transform="translate(16.361 -12.548)"
                        fill="#004b93"
                        fill-rule="evenodd"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
