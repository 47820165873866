import React from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenu.scss";
import { MGILeftMenuLink } from "../MGILeftMenuLink/MGILeftMenuLink";
import { MGILeftMenuDarkWhite } from "../MGILeftMenu/MGILeftMenuDarkWhite";
import { MGILeftMenuSeparation } from "../MGILeftMenu/MGILeftMenuSeparation";

import logoMGIPredict from "../assets/Logo_Predict.svg";
import logoMGITagGenerator from "../assets/Logo_Taggenerator.svg";
import logoMGIDashboard from "../assets/Logo-Dashboard.svg";
import logomgilittle from "../assets/logomgilittle.svg";
import { MGILeftMenuOpenPredictModal } from "./MGILeftMenuOpenPredictModal";
import { MGILeftMenuOpenDocumentionDashboard } from "./MGILeftMenuOpenDocumentionDashboard";

export interface MGILeftMenupProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // project name
  project: string;
  // items
  linkList: { linkleftmenu: string; svg: any; label: string; url: string }[];
  // switch theme
  switchTheme: boolean;
  // Documentation dashboard
  docdashboard?: { label: string; url: string };
  // Centre d'alertes
  machineType?: string;
  // alert link if it exists
  alerteLink?: { label: string; onClick: any };
  // actions
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenu = ({
  styleClass = "primary",
  theme,
  project,
  linkList,
  switchTheme = false,
  machineType = "",
  alerteLink,
  docdashboard,
  ...props
}: MGILeftMenupProps) => {
  const mode = "storybook-leftmenu--" + styleClass;
  const logo =
    project === "predict"
      ? logoMGIPredict
      : project === "dashboard"
      ? logoMGIDashboard
      : project === "taggenerator"
      ? logoMGITagGenerator
      : logoMGIPredict;

  return (
    <div
      data-theme={theme ? theme : "light"}
      className={["storybook-leftmenu", mode].join(" ")}
      {...props}
    >
      <div className="storybook-leftmenu-container">
        <div className="logocontainer">
          <div
            className={project === "dashboard" ? "logomgiDashboard" : "logomgi"}
          >
            <img src={logo} alt="Logo mgi connect" />
          </div>
        </div>
        <div className="container_list">
          <div className="list1">
            <ul>
              {linkList.map((link) => {
                return (
                  <>
                    {link.linkleftmenu !== "lien" ? (
                      <MGILeftMenuSeparation linkList={[]} />
                    ) : (
                      <li>
                        <div className="lilith">
                          <MGILeftMenuLink
                            theme={theme ? theme : "light"}
                            label={link.label}
                            svg={link.svg}
                            url={link.url}
                          />
                        </div>
                      </li>
                    )}
                  </>
                );
              })}
              {machineType === "ALPHAJET" && alerteLink && (
                <MGILeftMenuOpenPredictModal
                  theme={theme ? theme : "light"}
                  label={alerteLink.label}
                  onClick={alerteLink.onClick}
                />
              )}

              {docdashboard && (
                <MGILeftMenuOpenDocumentionDashboard
                  theme={theme ? theme : "light"}
                  label={docdashboard.label}
                  url={docdashboard.url}
                />
              )}
              {switchTheme && (
                <MGILeftMenuDarkWhite
                  theme={theme ? theme : "light"}
                  linkList={[]}
                />
              )}
            </ul>
          </div>
        </div>
        <div className="logomgicontainer">
          <div className="logomgibot">
            <img src={logomgilittle} alt="MGI" />
            <p>Digital Technology </p>
          </div>
        </div>
      </div>
    </div>
  );
};
