import { KeycloakLoginOptions } from "keycloak-js";
import React, { useEffect, useState } from "react";
import { ThemeType } from "./Components/TopBar";

export const AuthContext = React.createContext<AuthContextProps>(null!);

export type team = {
  id: string;
  name: string;
  realName: string;
  roles: string[];
};

export type UserProfile = {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isAdmin: boolean;
  availableApps: string[];
  userAvailableApps?: string[];
  approveModal: boolean;
  teams: team[];
  appTheme: ThemeType;
  defaultTeam?: team;
  company?: string;
  producer_team_uuid?: string;
  lang: string;
};

export const MissingOrgError = Symbol("MissingOrgError");

export interface Auth {
  authenticated?: boolean;
  login(options?: KeycloakLoginOptions): Promise<void>;
  logout: () => Promise<void>;
  loadUserProfile: () => Promise<UserProfile | undefined>;

  /**
   * @returns current valid token from keycloak
   */
  token?: string;

  /**
   * Refresh access token if it has expired
   * If token refresh failed, make user login again
   */
  refreshSession(): Promise<void>;
}

export type AuthContextProps = {
  initialized: boolean;
  auth: Auth;
};

export function useAuth(): AuthContextProps {
  return React.useContext(AuthContext);
}

export function useUser(): UserProfile | undefined {
  const { initialized, auth } = useAuth();
  const [user, setUser] = useState<UserProfile>();
  useEffect(() => {
    async function fetchProfile() {
      if (initialized && auth.authenticated) {
        const profile = await auth.loadUserProfile();
        setUser(profile);
      }
    }
    fetchProfile();
  }, [auth, initialized]);
  return user;
}
