import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translate } from "./translation";

export const appLanguages = ["en", "fr", "ja"] as const;
export type AppLanguage = typeof appLanguages[number];

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const i18nInstance = i18n.createInstance();

i18nInstance
  .use(initReactI18next)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    resources: translate,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18nInstance;
