import React, { ReactElement } from "react";
import { Position, Theme } from "../styles/types";
import "./MGILinks.scss";

export interface LinkProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // style
  backgroundColor?: any;
  borderColor?: any;
  color?: any;
  zIndex?: string;
  width?: string;
  // placement
  position?: Position;
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  // text to display
  label: string | ReactElement;
  // actions
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MGILinks = ({
  styleClass = "primary",
  theme,
  backgroundColor,
  borderColor,
  color,
  width,
  zIndex,
  position,
  top,
  left,
  right,
  bottom,
  label,
  ...props
}: LinkProps) => {
  const mode = "storybook-link--" + styleClass;
  return (
    <div
      data-theme={theme ? theme : "light"}
      className={["storybook-link", mode].join(" ")}
      style={{
        backgroundColor,
        borderColor,
        width,
        color,
        zIndex,
        position,
        top,
        left,
        right,
        bottom,
      }}
      {...props}
    >
      {label}
    </div>
  );
};
