import "./Internationalization/i18n";
import i18nInstance, { AppLanguage } from "./Internationalization/i18n";
import { I18nextProvider } from "react-i18next";
import { Bar } from "./Bar";
export type ThemeType = "dark" | "light";

export const TopBar = ({
  logo,
  pageName,
  changeLang,
  theme,
  handleUpdateUserTheme,
  currentLang,
  backendUrl,
  currentConnectPage,
  connectUrl,
}: {
  logo: string;
  pageName?: string;
  theme?: ThemeType;
  handleUpdateUserTheme?: (theme: ThemeType) => Promise<void>;

  changeLang: (lang: AppLanguage) => void;
  currentLang: string;
  backendUrl?: string;
  currentConnectPage?: string;
  connectUrl?: string;
}) => {
  return (
    <I18nextProvider i18n={i18nInstance}>
      <Bar
        logo={logo}
        pageName={pageName}
        theme={theme}
        handleUpdateUserTheme={handleUpdateUserTheme}
        changeLang={changeLang}
        currentLang={currentLang}
        backendUrl={backendUrl}
        currentConnectPage={currentConnectPage}
        connectUrl={connectUrl}
      />
    </I18nextProvider>
  );
};
