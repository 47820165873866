import React, { ReactElement, ReactNode } from "react";
import { Theme } from "../styles/types";
import "./MGILeftMenuLink.scss";
import { NavLink } from "react-router-dom";

export interface MGILeftMenuLinkProps {
  theme?: Theme;
  // class name
  styleClass?: string;
  // text to display
  label: string | ReactElement;
  svg: ReactNode;
  url: string;
  // actions
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MGILeftMenuLink = ({
  styleClass = "primary",
  theme,
  label,
  svg,
  url,
  ...props
}: MGILeftMenuLinkProps) => {
  const mode = "storybook-leftmenulink--" + styleClass;
  return (
    <NavLink
      to={url}
      data-theme={theme ? theme : "light"}
      className={({ isActive }) => {
        return isActive ? "active" : undefined;
      }}
    >
      <div
        data-theme={theme ? theme : "light"}
        className={["storybook-leftmenulink", mode].join(" ")}
        {...props}
      >
        <div className="svgzone">{svg}</div>
        <div className="linkZone">{label}</div>
      </div>
    </NavLink>
  );
};
