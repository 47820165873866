import React from "react";
import { Auth, AuthContext } from "./AuthContext";
import { ThemeType } from "./Components/TopBar";

// TODO : Remove
export class FakeAuth implements Auth {
  public readonly token: string | undefined;
  constructor(
    public readonly authenticated: boolean,
    public readonly linkedOrganization: boolean = true
  ) {
    if (authenticated) {
      this.token = "dummy-jwt-token";
    } else {
      this.token = undefined;
    }
  }

  login() {
    return Promise.resolve();
  }

  logout() {
    return Promise.resolve();
  }

  loadUserProfile() {
    return Promise.resolve({
      id: "555b2b24-5de3-4cbe-b5de-d1c23ffba364",
      firstName: "Fake",
      lastName: "User",
      email: "fake@email.com",
      isAdmin: false,
      userId: "fake-user",
      availableApps: ["approve", "catalog", "roi", "dashboard"],
      teams: [
        {
          id: "fe68d41c-d373-4832-9a60-c1cb01f2c5a2",
          name: "MGI Labs",
          realName: "MGI Labs",
          roles: ["approve"],
        },
      ],
      appTheme: "dark" as ThemeType,
      userAvailableApps: ["roi", "approve", "dashboard", "catalog"],
      defaultTeam: {
        id: "fe68d41c-d373-4832-9a60-c1cb01f2c5a2",
        name: "MGI Labs",
        realName: "MGI Labs",
        roles: ["approve"],
      },
      approveModal: false,
      lang: "fr",
    });
  }

  refreshSession() {
    return Promise.resolve();
  }
}

export const FakeAuthProvider: React.FunctionComponent<{
  children: React.ReactNode;
  initialized?: boolean;
  value?: Auth;
  linkedOrganization?: boolean;
}> = ({ children, initialized, value, linkedOrganization }) => {
  const auth = value ?? new FakeAuth(true, linkedOrganization);
  return (
    <AuthContext.Provider
      value={{
        initialized: initialized ?? true,
        auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
