import React from "react";
import "./MGIButtons.scss";

export interface ButtonProps {
  primary?: boolean;
  theme?: string;
  // class name
  styleClass?: string;
  // style
  backgroundColor?: any;
  borderColor?: any;
  color?: any;
  width?: string;
  // size
  size?: "small" | "medium" | "large";
  // text to display
  label: string;
  // actions
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MGIButtons = ({
  primary = false,
  styleClass = "primary",
  size = "medium",
  theme,
  backgroundColor,
  borderColor,
  color,
  width,
  label,
  ...props
}: ButtonProps) => {
  const mode = "storybook-button--" + styleClass;
  return (
    <button
      data-theme={theme ? theme : "light"}
      type="button"
      className={["storybook-button", `storybook-button--${size}`, mode].join(
        " "
      )}
      style={{ backgroundColor, borderColor, width, color }}
      {...props}
    >
      {label}
    </button>
  );
};
