import { PropsWithChildren } from "react";
import { FakeAuthProvider } from "./FakeAuthProvider";
import KeycloakAuthProvider from "./KeycloakAuthProvider";

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  if (process.env["REACT_APP_KEYCLOAK_ENABLE"]) {
    // Connect app with an actual keycloak server
    return <KeycloakAuthProvider>{children}</KeycloakAuthProvider>;
  } else {
    // Use a fake local authentication
    return <FakeAuthProvider>{children}</FakeAuthProvider>;
  }
}
