import classNames from "classnames";
import { SetStateAction, useCallback, useState } from "react";
import { team, useAuth } from "../AuthContext";
import styles from "./styles/navbar.module.scss";

export default function ListGroups({
  teams,
  defaultValue,
  backendUrl,
}: {
  teams: team[];
  defaultValue: team;
  backendUrl?: string;
}) {
  const [defaultGroup, setDefaultGroup] = useState(defaultValue.id);
  const { auth } = useAuth();
  const handleChangeTeam = useCallback(
    async (e: { target: { value: SetStateAction<string> } }) => {
      setDefaultGroup(e.target.value);
      const url = new URL(`${backendUrl}/user/change-user-group`).toString();
      await auth?.refreshSession();
      const init: RequestInit = {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth?.token}`,
        },
        body: JSON.stringify({ id: e.target.value }),
      };
      const response = await fetch(url, init);
      if (response.ok) {
        window.location.reload();
      }
    },
    []
  );

  return (
    <p className={classNames(styles.nameuser, styles.company)}>
      <select
        onChange={(e) => handleChangeTeam(e)}
        value={defaultGroup}
        className={classNames(styles.select)}
      >
        {teams.map((t) => (
          <option key={t.id} value={t.id}>
            {t.realName}
          </option>
        ))}
      </select>
    </p>
  );
}
